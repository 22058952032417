import React, { useState } from 'react';
import './css/Contactanos.css';
import logo from './img/undamLogo.png';

function Contactanos() {
  // Estados para el formulario
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  
  // URL del script PHP en tu servidor
  const baseUrl = "https://undamdesarrollos.com/pruebaCorreo.php";

  // Función para enviar el formulario
  const sendEmail = async () => {
    // Datos a enviar al backend PHP
    let dataSend = {
      email: email,
      name: name,
      lastName: lastName,
      message: message,
    };

    try {
      const res = await fetch(baseUrl, {
        method: "POST",
        body: JSON.stringify(dataSend), // Enviar datos como JSON
        headers: {
          "Content-Type": "application/json", // Cabecera para JSON
        },
      });

      const response = await res.json(); // Convertir la respuesta a JSON

      // Manejar la respuesta del backend
      if (response.success) {
        alert("¡Correo enviado correctamente!");
        // Limpia los campos del formulario
        setName("");
        setLastName("");
        setEmail("");
        setMessage("");
      } else {
        alert(`Error: ${response.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Ocurrió un error inesperado. Inténtalo de nuevo más tarde.");
    }
  };

  return (
    <div className="contact-container" id="contactanos">
      <div className="contact-form">
        <h2>Contáctanos</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="input-group">
            <label>Nombre <span className="required">(requerido)</span></label>
            <div className="name-inputs">
              <input 
                id="name" 
                type="text" 
                onChange={(e) => setName(e.target.value)} 
                value={name}
                placeholder="Nombre" 
                required 
              />
              <input 
                id="lastName" 
                type="text" 
                onChange={(e) => setLastName(e.target.value)} 
                value={lastName}
                placeholder="Apellido" 
                required 
              />
            </div>
          </div>
          <div className="input-group">
            <label>Email <span className="required">(requerido)</span></label>
            <input 
              id="mail" 
              type="email" 
              onChange={(e) => setEmail(e.target.value)} 
              value={email}
              placeholder="Correo electrónico" 
              required 
            />
          </div>
          <div className="input-group">
            <label>Mensaje <span className="required">(requerido)</span></label>
            <textarea 
              id="message" 
              type="text" 
              onChange={(e) => setMessage(e.target.value)} 
              value={message}
              placeholder="Escribe tu mensaje aquí" 
              required
            ></textarea>
          </div>
          <button type="submit" onClick={sendEmail} className="send-button">
            ENVIAR
          </button>
        </form>
      </div>
      <div className="logo-container">
        <img src={logo} className="logo" alt="Undam Logo" />
      </div>
    </div>
  );
}

export { Contactanos };
